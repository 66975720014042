import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, meta, title }) {
  const { pathname } = useLocation()

  const {
    wp: { generalSettings: siteMeta },
  } = useStaticQuery(
    graphql`
      {
        wp {
          generalSettings {
            description
            title
          }
        }
      }
    `
  )

  const metaDescription = description || siteMeta.description
  const defaultTitle = siteMeta.title
  const url = `https://diefotodudes.de${pathname}`

  return (
    <Helmet
      htmlAttributes={{
        lang: `de`,
      }}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | diefotodudes`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `/og_image.jpg`,
        },
        {
          property: `og:url`,
          content: url,
        },
      ].concat()}
    />
  )
}

export default SEO
