import React from "react"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <div className="antialiased bg-white min-h-screen">
      <Helmet>
        <link
          rel="preload"
          href="https://use.typekit.net/fxg4jde.css"
          as="style"
        />
        <link rel="stylesheet" href="https://use.typekit.net/fxg4jde.css" />
      </Helmet>
      {children}
    </div>
  )
}

export default Layout
